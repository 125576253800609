export default [
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/Tickets/Ticket.vue'),
    meta: {
      title: 'Case'
    }
  },
  {
    path: '/case/add',
    name: 'case-add',
    component: () => import('@/views/Tickets/components/TicketDynamicTab.vue'),
    meta: {
      title: 'Case Add'
    }
  },
  {
    path: '/case/detail/:id',
    name: 'case-detail',
    component: () => import('@/views/Tickets/components/TicketTabDetail.vue'),
    meta: {
      title: 'Case Detail'
    }
  }
]
