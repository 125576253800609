<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <Loading v-if="progressLoading > 0" />
    <component
      :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import ToastificationContentTicket from '@/components/ToastificationContentTicket.vue'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
// import TicketProvider from '@/resources/TicketProvider'
// import returnDataTicket from '@/utils/data-ticket'
import Loading from '@/components/Loading.vue'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import SocketioService from './services/socketio.service'

// const TicketService = new TicketProvider()

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    Loading,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  provide () {
    // use function syntax so that we can access `this`
    return {
      profileData: this.profile
    }
  },
  computed: {
    layout () {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType () {
      return this.$store.state.appConfig.layout.type
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user',
      progressLoading: 'activity/progressLoading'
    })
  },
  beforeCreate () {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup () {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses
    }
  },
  mounted () {
    if (this.profile) {
      SocketioService.setupSocketConnection(this.profile.id)
      SocketioService.socketOn('popup', async (payload) => {
        // const { data } = await TicketService.createInitial(this.profile.id)
        // const ticket = returnDataTicket(data)
        // store.dispatch('ticket/ADD_TICKET', {
        //   ...ticket,
        //   ani: payload?.customerProfile?.ani || '',
        //   customerProfileId: payload?.customerProfile?.id || '',
        //   customerName: payload?.customerProfile?.name || ''
        // })
        this.$toast({
          component: ToastificationContentTicket,
          listeners: {
            click: () => {
              this.$router.push('/case/add')
            }
          },
          props: {
            title: payload?.customerProfile?.ani || '',
            icon: 'PhoneIncomingIcon',
            variant: 'success',
            text: payload?.skillName || '',
            hideClose: false
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
