/* eslint-disable import/no-cycle */
import store from '@/store'

export const getAccessToken = () => store.getters['User/accessToken']

export const getAuthToken = () => {
  let token = {}
  const accessToken = getAccessToken()
  if (accessToken) {
    token = {
      key: 'Authorization',
      value: `Bearer ${accessToken}`
    }
  }
  return token
}
export const isUserLoggedIn = () => localStorage.getItem('userData') && true
// && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'super admin') return '/'
  return { name: 'login' }
}
