import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAccessToken } from '../auth/utils'
// Routes
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import customers from './routes/customers'
import tickets from './routes/tickets'
import knowledges from './routes/knowledges'
import reports from './routes/reports'
import favoriteEmail from './routes/favorite-email'
import dashboardsReport from './routes/dashboards-report'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        title: 'Home'
        // pageTitle: 'Dashboard',
        // breadcrumb: [
        //   {
        //     text: 'Dashboard',
        //     active: true
        //   }
        // ]
      }
    },
    ...customers,
    ...tickets,
    ...knowledges,
    ...favoriteEmail,
    ...reports,
    ...dashboardsReport,
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true
          }
        ]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

const publicRoute = ['login']
// const superAdminRoute = ['ClientList', 'AdminList', 'AdminAdd']
// const nonSuperAdminRoute = ['ReportHome']

router.beforeEach((to, from, next) => {
  const routeName = to.name

  // const role = getRole()

  if (publicRoute.some((pr) => pr === routeName)) {
    next()
  } else {
    const token = getAccessToken()

    if (!token) {
      next({ name: 'login' })
    } else {
      next()
    }
  }
  return next()
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'login' })

//     // If logged in => not authorized
//     return next({ name: 'second-page' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
