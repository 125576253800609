import AuthProvider from '@/resources/AuthProvider'

const AuthService = new AuthProvider()

const state = {
  accessToken: '',
  user: {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    userGroupId: '',
    userTeamId: ''
  }
}

const getters = {
  user: (state) => state.user,
  accessToken: (state) => state.accessToken
}

const mutations = {
  SET_USER (state, payload) {
    state.accessToken = payload.token
    state.user.id = payload.id
    state.user.username = payload.username
    state.user.firstName = payload.firstName
    state.user.lastName = payload.lastName
    state.user.email = payload.email
    state.user.role = payload.role
    state.user.userGroupId = payload.userGroupId
    state.user.userTeamId = payload.userTeamId
  },

  RESET_USER (state) {
    state.accessToken = ''
    state.user.id = ''
    state.user.username = ''
    state.user.firstName = ''
    state.user.lastName = ''
    state.user.email = ''
    state.user.role = ''
    state.user.userGroupId = ''
    state.user.userTeamId = ''
  }
}

const actions = {
  async signIn ({ dispatch }, payload) {
    const { data } = await AuthService.login({ username: payload.username, password: payload.password })
    if (data.user.role === 'super admin') {
      throw new Error('Unauthorized')
    } else if (data.token) {
      dispatch('setUser', {
        ...data.user,
        token: data.token
      })
    }
  },

  resetUser ({ commit }) {
    commit('RESET_USER')
  },

  setUser ({ commit }, payload) {
    commit('SET_USER', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
