export default [
  {
    path: '/dashboard-report',
    name: 'dashboardsReport',
    component: () => import('@/views/DashboardsAndReport/DashboardsAndReport.vue'),
    meta: {
      title: 'Dashboards & Report'
    }
  }
]
