export default [
  {
    path: '/report/ticket-detail',
    name: 'report-ticket-detail',
    component: () => import('@/views/Reports/TicketDetail.vue'),
    meta: {
      title: 'Report Case Detail'
    }
  },
  {
    path: '/report/summary-by-agent',
    name: 'report-summary-by-agent',
    component: () => import('@/views/Reports/SummaryByAgent.vue'),
    meta: {
      title: 'Report Summary By Agent'
    }
  },
  {
    path: '/report/summary-by-channel',
    name: 'report-summary-by-channel',
    component: () => import('@/views/Reports/SummaryByChannel.vue'),
    meta: {
      title: 'Report Summary By Channel'
    }
  },
  {
    path: '/report/summary-by-case-type',
    name: 'report-summary-by-case-type',
    component: () => import('@/views/Reports/SummaryByCaseType.vue'),
    meta: {
      title: 'Report Summary By Case Type'
    }
  }
]
