const defaultState = {
  tickets: []
}

const getters = {
  tickets (state) {
    return state.tickets
  }
}

const mutations = {
  SET_TICKET (state, payload) {
    state.tickets = [...state.tickets, payload]
  },

  OVERWRITE_TICKET (state, payload) {
    state.tickets[payload.index] = payload.payload
  },

  REMOVE_TICKET (state, index) {
    state.tickets.splice(index, 1)
  }
}

const actions = {
  ADD_TICKET ({ commit }, payload) {
    commit('SET_TICKET', payload)
  },

  OVERWRITE_TICKET ({ commit }, payload) {
    commit('OVERWRITE_TICKET', payload)
  },

  DELETE_TICKET ({ commit }, index) {
    commit('REMOVE_TICKET', index)
  }

}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  mutations,
  actions
}
