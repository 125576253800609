import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import wysiwyg from 'vue-wysiwyg'
import VueDatePicker from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/quill-editor'
import '@/libs/vue-select'
import '@/libs/vue-ripple'
import '@/libs/dayjs'
import '@/libs/statusData'
import '@/libs/statusValue'
import '@/libs/caseStatus'
import '@/libs/ticketValue'
import '@/libs/format-status'
import '@/libs/format-status-value'
import '@/libs/vee-validate'
import '@/libs/prototype'
import '@/libs/click-outside'
import '@/libs/permission'
import '@/libs/wrap-word'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(wysiwyg, {})
Vue.use(VueDatePicker)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
