import { io } from 'socket.io-client'
import user from '@/store/user'

class SocketioService {
  socket;

  constructor () {
    this.setupSocketConnection(user?.getters?.user?.id || 0)
  }

  setupSocketConnection (id) {
    this.socket = io(`${process.env.VUE_APP_API_URL}/agent-${id}`, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity
    })
  }

  socketOn (eventName, callback) {
    this.socket.on(eventName, callback)
  }

  disconnect () {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}

export default new SocketioService()
